import ImageLink from '@ui/Link/ImageLink';

interface PopularCategoriesProps {}

const PopularCategories = ({}: PopularCategoriesProps) => {
  const categories = [
    {
      href: '/c/digital-cameras',
      src: 'https://gearfocus.b-cdn.net/public/digital_cameras.png',
      alt: 'Browse New Or Uses Digital Cameras',
      label: 'Digital Cameras',
    },
    {
      href: '/c/photo-video-lenses',
      src: 'https://gearfocus.b-cdn.net/public/lens.png',
      alt: 'Shop For Camera Filters & Lenses',
      label: 'Camera filters & lenses',
    },
    {
      href: '/c/video-camcorders',
      src: 'https://gearfocus.b-cdn.net/public/video.png',
      alt: 'Shop Preowned Video and Camera Camcorders',
      label: 'Video & Camera Camcorders',
    },
    {
      href: '/c/lighting-studio',
      src: 'https://gearfocus.b-cdn.net/public/lighting.png',
      alt: 'Browse All New And Used Lighting & Studio Equipment',
      label: 'Lighting & Studio',
    },
    {
      href: '/c/drones-aerial-imaging',
      src: 'https://gearfocus.b-cdn.net/public/drone.png',
      alt: 'Shop For Drone and Aerial Imaging Gear',
      label: 'Drone & Aerial Imaging',
    },
    {
      href: '/c/computers-electronics',
      src: 'https://gearfocus.b-cdn.net/public/computer_electronics.png',
      alt: 'Browse Computers and Electronics For Sale',
      label: 'Computers & Electronics',
    },
  ];
  return (
    <div className="mx-[1.6rem] flex gap-[1.6rem] overflow-x-scroll pb-4 sm:mx-0 sm:w-full sm:flex-wrap sm:justify-center sm:overflow-auto sm:pb-0">
      {categories.map((category) => (
        <ImageLink
          key={category.label}
          src={category.src}
          href={category.href}
          alt={category.alt}
          label={category.label}
          variant="square"
        />
      ))}
    </div>
  );
};

export default PopularCategories;
