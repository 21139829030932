import { useEffect, useMemo, useRef } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'

const SCRIPT_SRC_BASE = 'https://app.termly.io'

interface BannerProps {
  autoBlock?: boolean
  masterConsentsOrigin?: string
  websiteUUID: string
}

const ConsentBanner: React.FC<BannerProps> = ({ autoBlock, masterConsentsOrigin, websiteUUID }) => {
  const scriptSrc = useMemo(() => {
    const src = new URL(SCRIPT_SRC_BASE)
    src.pathname = `/resource-blocker/${websiteUUID}`
    if (autoBlock) {
      src.searchParams.set('autoBlock', 'on')
    }
    if (masterConsentsOrigin) {
      src.searchParams.set('masterConsentsOrigin', masterConsentsOrigin)
    }
    return src.toString()
  }, [autoBlock, masterConsentsOrigin, websiteUUID])

  const isScriptAdded = useRef(false)

  useEffect(() => {
    if (isScriptAdded.current) return

    const script = document.createElement('script')
    script.src = scriptSrc
    script.async = true
    script.onload = () => {
      // Initialize Termly after the script is loaded
      if (window.Termly && typeof window.Termly.initialize === 'function') {
        window.Termly.initialize()
      }
    }
    script.onerror = () => {
      console.error('Failed to load Termly script.')
    }
    document.head.appendChild(script)
    isScriptAdded.current = true

    return () => {
      // Cleanup script tag on component unmount
      const existingScript = document.querySelector(`script[src="${scriptSrc}"]`)
      if (existingScript) {
        existingScript.remove()
        isScriptAdded.current = false
      }
    }
  }, [scriptSrc])

  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    // Ensure Termly is re-initialized when the URL changes
    if (window.Termly && typeof window.Termly.initialize === 'function') {
      window.Termly.initialize()
    }
  }, [pathname, searchParams])

  return null
}

export default ConsentBanner