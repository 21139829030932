type InfoHighlightProps = {
  title: string;
  description: string;
  icon: React.ReactNode;
};

const InfoHighlight = ({ title, description, icon }: InfoHighlightProps) => {
  return (
    <div className="flex min-h-[12rem] w-full items-center bg-[#EDEDED] px-[3.2rem] py-[0.8rem] sm:w-[38.4rem] rounded-[10px]">
      <div className="flex h-[4rem] w-[4rem] shrink-0 items-center justify-center rounded-full bg-brand-primary text-white">
        {icon}
      </div>
      <div className="ml-[1.6rem]">
        <h3 className="mb-[0.8rem] text-[1.8rem] font-semibold">{title}</h3>
        <p className="text-[1.3rem]">{description}</p>
      </div>
    </div>
  );
};

export default InfoHighlight;
