import CallToAction from '@c/CallToAction';
import HomepageHeader from '@c/headers/homepage/HomepageHeader';
import { CheckmarkIcon, CoinIcon, PageIcon, ProtectIcon } from '@c/icons';
import { DefaultLayout } from '@c/layouts';
import PopularCategories from '@c/PopularCategories';
import TopBrands from '@c/TopBrands';
import Button from '@ui/Button';
import { HomeLinkRow } from '@c/HomeLinkRow';
import InfoHighlight from '@ui/InfoHighlight';
import PageSectionHeader from '@ui/PageSectionHeader';
import Review from '@ui/Review';
import SafeImage from '@ui/SafeImage';
import { getYTVideosFromWidget, Video } from '@util/external/youtube';
import { getNBlogs } from '@util/firestore/blog';
import {
  getFeaturedProducts,
  getNewArrivals,
  getRecentlySold,
} from '@util/firestore/products';
import { STATIC_REVIEWS } from '@util/firestore/reviews';
import { BlogDocument } from '@util/types/firestore/blog';
import { ProductDocument } from '@util/types/firestore/products';
import { useAuth } from 'context/AuthContext';
import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import useRealtimeAuctions from '@util/hooks/useRealtimeAuctions';
import useNewGearFocusModal from '@util/hooks/useNewGearFocusModal';
import Link from 'next/link';
// import saleImage from '@c/headers/i/misc/Black-Friday-home-Page-Banner.jpg';
//import AdsenseCard from '@c/cards/AdsenseCard';

// Dynamic Imports
const NewGearFocusModal = dynamic(() => import('@c/modals/NewGearFocusModal'), {
  ssr: false,
});
const Carousel = dynamic(() => import('@ui/Carousel'), { ssr: false });
const BlogLink = dynamic(() => import('@ui/Link/BlogLink'), { ssr: false });
const ProductCard = dynamic(() => import('@c/cards/ProductCard'), {
  ssr: false,
});
const VideoCard = dynamic(() => import('@c/cards/VideoCard'), { ssr: false });
// const TopBrands = dynamic(() => import('@c/TopBrands'));

const renderProductCards = (productItems: ProductDocument[]) =>
  productItems.map((item, i) => (
    <ProductCard item={item} key={item.sku + '-' + i} />
  ));

const Span = ({ children }: any) => {
  return (
    <span className="flex gap-[1.6rem] text-input font-normal text-brand-white ">
      <CheckmarkIcon />
      {children}
    </span>
  );
};

type HomePageProps = {
  featuredProducts: ProductDocument[];
  newArrivals: ProductDocument[];
  recentlySold: ProductDocument[];
  videos: Video[];
  recentBlogPosts: BlogDocument[];
};

export default function Home({
  featuredProducts,
  newArrivals,
  recentlySold,
  videos,
  recentBlogPosts,
}: HomePageProps) {
  const { userDoc } = useAuth();
  const [showNewGearFocusModal, setShowNewGearFocusModal] =
    useNewGearFocusModal();
  const auctions = useRealtimeAuctions();

  return (
    <div className="w-full">
      {showNewGearFocusModal && (
        <NewGearFocusModal
          isOpen={showNewGearFocusModal}
          dismiss={() => {
            setShowNewGearFocusModal(false);
          }}
        />
      )}
      <HomepageHeader />
      <div className="mx-auto mt-[2rem] flex w-full max-w-default flex-col gap-[4rem] overflow-clip sm:mt-[9.6rem] sm:gap-[9.6rem] sm:px-default-padding-x">
        <div>
          <PageSectionHeader title="Popular Categories" />
          <PopularCategories />
        </div>
        {/* Sale Banner 
        <div className="w-full">
          <Link href="/onsale">
            <SafeImage
              height={375}
              width={1800}
              src={saleImage}
              key={'sale image'}
              alt={'2023 Black Friday Sale'}
            />
          </Link>
        </div> */}
        {auctions.realTimeProducts.length > 0 && (
          <div>
            <PageSectionHeader
              title="Current auctions"
              href="/auctions"
              linkText="See All"
            />
            <Carousel items={renderProductCards(auctions.realTimeProducts)} />
          </div>
        )}
        <div>
          <PageSectionHeader
            title="Featured products"
            href="/featured"
            linkText="See All"
            linkTitle="Browse Our Featured New & Used Cameras And Lenses"
          />
          <Carousel items={renderProductCards(featuredProducts)} />
        </div>
        <div>
          <PageSectionHeader
            title="Top Brands"
            linkText="See All Brands"
            linkTitle="Browse Brands Such As Canon, Nikon, Sony And More!"
            href="/brands"
          />
          <TopBrands />
        </div>
        <CallToAction
          reverse
          image={
            <SafeImage
              className="hidden sm:block"
              src="https://gearfocus.b-cdn.net/public/contact_camera.png"
              alt="Call to Action"
              width={600}
              height={345}
            />
          }
        >
          <div className="flex h-full flex-col justify-center p-[1.6rem] sm:p-[1.6rem]">
            <span className="text-left text-h3 font-semibold sm:text-h2 sm:font-bold">
              Buy and Sell Used Gear With Confidence
            </span>
            <span className="mb-[3rem] text-left">
              Gear Focus is a trusted marketplace for buying and selling used photography and video equipment, crafted by creators.
              Join a trusted community tailored for photographers and videographers and start buying and selling with ease.
            </span>
            <div className="flex flex-wrap gap-[1.2rem]">
              <div className="flex flex-col gap-[1.2rem] sm:mr-[3.2rem]">
                <Span>Made by Creatives, for Creatives</Span>
                <Span>Only 5% Seller Fees</Span>
                <Span>Buyer and Seller Protection</Span>
              </div>
              <div className="flex flex-col gap-[1.2rem]">
                <Span>Target Market</Span>
                <Span>Unlimited Free Listings!</Span>
              </div>
            </div>
            <div className="mt-[2rem] sm:mt-[4rem] sm:max-w-[25.6rem]">
              <Button
                text="Sell your gear"
                title="Sell Your Used Cameras Or Lenses With Gear Focus"
                href={
                  userDoc?.account_activated
                    ? '/create-listing'
                    : '/sellyourgear'
                }
                height="small"
              />
            </div>
          </div>
        </CallToAction>
        <div>
          <PageSectionHeader
            title="New Arrivals"
            href="/new-arrivals"
            linkTitle="Discover Newly Listed Cameras And Lenses"
            linkText="See All"
          />
          <Carousel items={renderProductCards(newArrivals)} />
        </div>

        <HomeLinkRow />

          
          <div className="px-4 sm:px-0">
            <PageSectionHeader
              title="Latest News From The Studio"
              href="/blog"
              linkText="See More Blogs"
              variant="secondary"
            />
            <div className="flex gap-[2.4rem] overflow-x-auto">
              {recentBlogPosts.map((post) => (
                <BlogLink key={post.id} blog={post} />
              ))}
            </div>
          </div>
        {/* youtube videos */}
        <div className="px-4 sm:px-0">
          <PageSectionHeader
            title="We're Creators, Just Like You"
            href="/videos"
            linkText="View our Videos"
            variant="secondary"
          />
          <div className="flex gap-[2.4rem] overflow-x-auto">
            {videos.map((v) => {
              return <VideoCard key={v.videoId} video={v} />;
            })}
          </div>
        </div>
        <div>
          <PageSectionHeader title="See What's Selling" />
          <Carousel items={renderProductCards(recentlySold)} />
        </div>
        <div className="flex flex-col gap-[1.6rem]">
          <h2 className="text-center text-[2.4rem] font-semibold sm:text-h2">
            See What Our Customers Say
          </h2>
          <Carousel
            incrementBy={1}
            items={STATIC_REVIEWS?.map((review) => (
              <Review
                key={review.id}
                img={review.img}
                name={review.name}
                content={review.content}
                googleReviewLink={review.googleReviewLink}
              />
            ))}
            showPaginator
          />
        </div>
        <div>
          {/* CTA banner */}
          <div className="relative m-auto mb-[1.6rem] mt-[2rem] max-w-[120rem] overflow-clip rounded-[10px] px-[1rem] sm:px-0">
            {/* background image */}
            <div className="h-[36rem] w-full overflow-clip rounded-[10px] bg-brand-black bg-homepage-cta bg-cover bg-center" />
            {/* content */}
            <div className="absolute left-0 right-0 top-0 m-auto mt-[10rem]">
              <div className="z-10 flex flex-col items-center text-center text-brand-lightest-black">
                <span className="mb-[.8rem] select-none text-[32px] font-semibold">
                  Start Earning Today!
                </span>
                <span className="select-none font-normal">
                  Open your own shop and sell your collections.
                </span>
                <div className="mt-[2.4rem]">
                  <Button
                    text="Sell Your Gear"
                    href={
                      userDoc?.account_activated
                        ? '/create-listing'
                        : '/sellyourgear'
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="hidden flex-wrap justify-between sm:my-[3rem] sm:flex sm:gap-[1.6rem]">
            <InfoHighlight
              icon={<ProtectIcon />}
              title="Lowest Fees"
              description="List for free. If it sells, our 5% commission fee is the lowest in the business."
            />
            <InfoHighlight
              icon={<CoinIcon />}
              title="5-Star Buyer Community"
              description="We place your listing in front of thousands of high-quality creators just like you!"
            />
            <InfoHighlight
              icon={<PageIcon />}
              title="Seamless Shipping"
              description="Get shipping quotes and purchase a shipping label directly from Gear Focus with ease."
            />
          </div>
          <div className="hidden flex-wrap justify-between sm:mb-[6rem] sm:flex sm:gap-[1.6rem]">
            <Link href="https://imagen-ai.com/?utm_source=GearFocus&utm_campaign=Newsletter_Aug23&utm_content=Gif_AIPowered" target="_blank">
              <SafeImage
                width={380}
                height={120}
                src="/promos/homepage/imagen-ad.jpg"
                alt="promotion"
                className="rounded-[10px]"
              />
            </Link>
            <Link href="https://geni.us/42framesad" target="_blank">
              <SafeImage
                width={380}
                height={120}
                src="/promos/homepage/52-frames-contest.jpg"
                alt="promotion"
                className="rounded-[10px]"
              />
            </Link>
            <Link href="https://geni.us/kl0DkL" target="_blank">
              <SafeImage
                width={380}
                height={120}
                src="/promos/homepage/Polarpro.jpg"
                alt="promotion"
                className="rounded-[10px]"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

Home.getLayout = function getLayout(page: React.ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>;
};

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
  const [
    featuredProductsResponse,
    newArrivalsResponse,
    recentlySoldResponse,
    videosResponse,
    recentBlogPostsResponse,
  ] = await Promise.all([
    getFeaturedProducts(),
    getNewArrivals(),
    getRecentlySold(),
    getYTVideosFromWidget(),
    getNBlogs(3),
  ]);

  const desc = 'Buy used cameras effortlessly with Gear Focus, a trusted marketplace for secure transactions of new and used camera gear. Sell and upgrade with assurance.';
  return {
    props: {
      featuredProducts: featuredProductsResponse.results,
      newArrivals: newArrivalsResponse.results,
      recentlySold: recentlySoldResponse.results,
      videos: videosResponse.videos,
      recentBlogPosts: recentBlogPostsResponse.results,
      title: 'Buy and Sell Used Camera & Lenses With Gear Focus',
      metaTags: [
        {
          attributes: {
            property: 'og:title',
            content: 'Buy and Sell Used Camera & Lenses With Gear Focus',
          },
          key: 'og:title',
        },
        {
          attributes: {
            property: 'og:image',
            content: '/gear_pile.png',
          },
          key: 'og:image',
        },
        {
          attributes: {
            name: 'keywords',
            content: 'Used cameras for sale, Buy ussed cameras, Pre-owned DSLR cameras, Second-hand camera gear, Used photography equipment, Buy used camera lenses, Affordable used cameras, Used digital cameras, Used professional cameras, Second-hand photography gear, Used camera marketplace'
          },
          key: 'keywords',
        },
        {
          attributes: {
            name: 'description',
            content: desc,
          },
          key: 'description',
        },
        {
          attributes: {
            property: 'og:description',
            content: desc,
          },
          key: 'og:description',
        },
        {
          attributes: {
            property: 'og:type',
            content: 'website',
          },
          key: 'og:type',
        },
        {
          attributes: {
            property: 'og:site_name',
            content: 'Gear Focus, LLC.',
          },
          key: 'og:site_name',
        },
      ],
    },
    revalidate: 300,
  };
};
