import { db } from '@util/firebase';
import {
  collection,
  CollectionReference,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { CategoryDocument } from './categories.types';

const storage = getStorage();

export const colRef = collection(
  db,
  'categories'
) as CollectionReference<CategoryDocument>;

export async function getCategories(maxLevel = 5) {
  try {
    const q = query(
      colRef,
      where("level", "<=", maxLevel),
      orderBy("level", "asc"),
      orderBy("order", "asc")
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map((doc) => doc.data() as CategoryDocument);
  } catch (error) {
    console.error("Error fetching categories:", error);
    return [];
  }
}

export async function getChildCategories(parent: string) {
  const q = query(colRef, where("parents", "array-contains", parent));
  const { docs } = await getDocs(q);
  return docs.map((doc) => doc.data());
}

export async function buildCategorySlug(categories: CategoryDocument[] | null, currentCategory: CategoryDocument) {
  let slug = currentCategory.slug;
  let parentId = currentCategory.parents && currentCategory.parents[0];

  while (parentId) {
    if (!categories) categories = await getCategories()

    const parentCategory = categories.find((cat: CategoryDocument) => cat?.id === parentId);
    if (parentCategory) {
      slug = parentCategory.slug + '/' + slug;
      parentId = parentCategory.parents && parentCategory.parents[0];
    } else {
      break;
    }
  }

  return ('/c/' + slug).toString()
}

export async function getCategoryBySlug(slug: string | null) {
  if (!slug) return null;

  const docRef = doc(colRef, slug);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();
  return data ?? null;
}

export async function getProductCategories(categories?: string[]) {
  if (!categories || categories.length === 0) {
    return [];
  }

  const categoryDocs = await Promise.all(
    categories.map(async (cat) => {
      const q = query(colRef, where("name", "==", cat));
      const snapshot = await getDocs(q);
      return snapshot.docs[0]?.data()
    })
  );

  return categoryDocs
}