import {
  arrayRemove,
  arrayUnion,
  collection,
  CollectionReference,
  deleteField,
  doc,
  getCountFromServer,
  limit,
  updateDoc,
} from '@firebase/firestore';
import { db, functions, httpsCallable } from '@util/firebase';
import { nonNullable } from '@util/index';
import { logError } from '@util/logError';
import { CarrierCode } from '@util/maps/carriers';
import {
  OrderDocument,
  orderDocumentSchema,
  OrderItemDocument,
  OrderNoteType,
  OrderStates,
  OrderStateType,
} from '@util/types/firestore/order';
import {
  endBefore,
  getDoc,
  getDocs,
  limitToLast,
  orderBy,
  query,
  QueryConstraint,
  startAfter,
  where,
} from 'firebase/firestore';
import { z } from 'zod';

export const ordersRef = collection(
  db,
  'orders'
) as CollectionReference<OrderDocument>;

const getRef = (id: string) => doc<OrderDocument>(ordersRef, id);

export const getOrderById = async (
  id: string
): Promise<OrderDocument | null> => {
  const snapshot = await getDoc(getRef(id));
  try {
    const data = orderDocumentSchema.parse(snapshot.data());
    return data;
  } catch (e) {
    logError(e);
    return null;
  }
};

export const markAsDelivered = (order: OrderDocument) => {
  return updateDoc(getRef(order.id), {
    sellers: order.sellers,
    total_state: 1,
    updated: Date.now(),
    tracking_error: deleteField(),
  });
};

export const updateOrderByKey = async (
  orderId: string,
  key: keyof OrderDocument,
  value: any
) => {
  await updateDoc(getRef(orderId), {
    [key]: value,
    updated: Date.now(),
  });
};

export const updateOrderItemState = async (
  order: OrderDocument,
  sellerId: string,
  itemId: string,
  state: number
) => {
  // Extracts the sellers from the order.
  const sellers = order.sellers;
  
  // Finds the index of the item within the seller's list.
  const index =
    sellers?.[sellerId].findIndex((item) => item.id === itemId) ?? -1;
  
  // Checks if the item was found (index >= 0) and if the sellers list exists.
  if (index >= 0 && sellers) {
    // Updates the state of the specific item.
    sellers[sellerId][index].state = state;
    
    // Initializes a flag to check if all items are complete (state >= 3).
    let allComplete = true;
    
    // A set to keep track of unique states across all items.
    const states = new Set<number>();

    // Loops through each seller's items to update states and check completion.
    Object.values(sellers).forEach((items) =>
      items.forEach((item) => {
        // Adds the item's state to the set.
        states.add(item.state);
        // If any item's state is less than 3, sets allComplete to false.
        if (item.state < 3) allComplete = false;
      })
    );

    // Initializes a variable to hold the total state of the order.
    let total_state = order.total_state;

    // Updates total_state based on the states of individual items.
    if (states.has(6) || states.has(7)) {
      // If any item is in state 6 or 7, sets total order state to 3.
      total_state = 3;
    } else if (allComplete) {
      // If all items are complete, sets total order state to 2.
      total_state = 2;
    } else if (states.has(2)) {
      // If any item is in state 2, sets total order state to 1.
      total_state = 1;
    } else {
      // If none of the above, sets total order state to 0.
      total_state = 0;
    }

    // Updates the order document with the new sellers state and total state.
    await updateDoc(getRef(order.id), {
      sellers,
      total_state,
      updated: Date.now(),
    });
  }
};

export const getOrderByOrderNum = async (
  order_num: number
): Promise<OrderDocument | null> => {
  const q = query(ordersRef, where('order_num', '==', order_num), limit(1));
  const snapshot = await getDocs(q);
  const data = snapshot.docs.map((doc) => {
    return orderDocumentSchema.parse(doc.data());
  });
  if (data) {
    return data[0];
  }
  return null;
};

export const getAllBuyerOrders = async (uid: string, isAdmin = false) => {
  const q = isAdmin
    ? query(ordersRef, orderBy('created', 'desc'))
    : query(
        ordersRef,
        where('buyer_id', '==', uid),
        orderBy('created', 'desc')
      );
  const snapshot = await getDocs(q);
  const data = snapshot.docs.map((doc) => {
    // return orderDocumentSchema.parse(doc.data()); // TODO: fix this
    return doc.data();
  });

  if (typeof data === 'undefined') {
    return { results: null };
  }
  return { results: data };
};

async function getValidCursorOrNull(
  cursor: string,
  direction: 'next' | 'prev',
  constraints: QueryConstraint[] = []
) {
  if (!cursor) return null;
  const cursorSnapshot = await getDoc(doc(ordersRef, cursor));
  const startAfterOrEndBefore = direction === 'next' ? startAfter : endBefore;
  const q = query(
    ordersRef,
    ...constraints,
    startAfterOrEndBefore(cursorSnapshot)
  );
  const snapshot = await getDocs(q);
  const document = snapshot.docs[0];
  if (!document) {
    return null;
  }
  return cursor;
}

interface GetOrdersArgs {
  uid: string;
  isAdmin?: boolean;
  adminUid?: string | null; // uid to get orders for as admin
  lastKey?: string | null;
  orderStatus: OrderStateType | 'all';
}
export const getAllBuyerOrdersPaginated = async (args: GetOrdersArgs) => {
  const { uid, isAdmin, adminUid, lastKey, orderStatus } = args;
  if (!uid && !isAdmin) return Promise.resolve({ results: [] });

  const limitValue = 8;
  const lastDoc = lastKey ? await getDoc(doc(ordersRef, lastKey)) : null;

  const filterConstraints = [
    ...(orderStatus !== 'all'
      ? [where('states', 'array-contains', OrderStates.indexOf(orderStatus))]
      : []),
  ];

  const getAll = (lastDoc: any) =>
    query(
      ordersRef,
      orderBy('created', 'desc'),
      ...(lastDoc ? [startAfter(lastDoc)] : []),
      ...filterConstraints,
      limit(limitValue)
    );

  const getByUid = (buyerId: string, lastDoc: any) =>
    query(
      ordersRef,
      where('buyer_id', '==', buyerId),
      orderBy('created', 'desc'),
      ...(lastDoc ? [startAfter(lastDoc)] : []),
      ...filterConstraints,
      limit(limitValue)
    );

  const q =
    adminUid && isAdmin
      ? getByUid(adminUid, lastDoc)
      : isAdmin
      ? getAll(lastDoc)
      : getByUid(uid, lastDoc);
  const snapshot = await getDocs(q);
  const data = snapshot.docs.map((doc) => {
    return orderDocumentSchema.parse(doc.data());
  });
  return { results: data };
};

// TODO: This is not being used. Can be removed?
export const getOrderStateNumberFromStatus = (status: string) => {
  switch (status) {
    case 'orderPlaced':
      return 0;
    case 'delivered':
      return 1;
    case 'complete':
      return 2;
    case 'onHold':
      return 3;
    case 'returnRequested':
      return 4;
  }
};

export const getAllSellerOrdersPaginated = async (args: {
  uid: string;
  isAdmin?: boolean;
  adminUid?: string | null; // uid to get orders for as admin
  lastKey?: string;
  limit?: number;
  pageInfo?: {
    cursor?: string;
    direction?: 'next' | 'prev';
  };
}) => {
  const resultsLimit = args.limit ?? 5;
  let cursorConstraint: QueryConstraint | undefined;
  let limitOrLimitToLast = limit;
  if (args.pageInfo && args.pageInfo.cursor && args.pageInfo.direction) {
    const { cursor, direction } = args.pageInfo;
    const startAfterOrEndBefore = direction === 'next' ? startAfter : endBefore;
    const cursorSnapshot = await getDoc(doc(ordersRef, cursor));
    cursorConstraint = startAfterOrEndBefore(cursorSnapshot);
    limitOrLimitToLast = direction === 'prev' ? limitToLast : limit;
  }

  const contraintsUnlimited = [
    !args.isAdmin || args.adminUid
      ? where('seller_arr', 'array-contains', args.adminUid || args.uid)
      : null,
    orderBy('created', 'desc'),
  ].filter(nonNullable);

  const constraintsWithCursor = [
    ...contraintsUnlimited,
    cursorConstraint,
  ].filter(nonNullable);

  const qLimited = query(
    ordersRef,
    ...constraintsWithCursor,
    limitOrLimitToLast(resultsLimit)
  );
  const qUnlimited = query(ordersRef, ...contraintsUnlimited);

  const limitedPromise = getDocs(qLimited);
  const unlimitedPromise = getCountFromServer(qUnlimited);

  const [limitedSnapshot, totalResultsSnapshot] = await Promise.all([
    limitedPromise,
    unlimitedPromise,
  ]);

  const results = limitedSnapshot.docs.map((doc) => doc.data());
  const prevId = results[0]?.id;
  const nextId = results[results.length - 1]?.id;
  const [prev, next] = await Promise.all([
    getValidCursorOrNull(prevId, 'prev', contraintsUnlimited),
    getValidCursorOrNull(nextId, 'next', contraintsUnlimited),
  ]);
  const totalResults = totalResultsSnapshot.data().count;

  return {
    results,
    pageInfo: {
      totalResults,
      currentCursor: args.pageInfo?.cursor ?? null,
      direction: args.pageInfo?.direction ?? null,
      next,
      prev,
    },
  };
};

export const getAllSellerOrders = async (uid: string) => {
  // where uid in seller_arr
  const q = query(
    ordersRef,
    where('seller_arr', 'array-contains', uid),
    orderBy('created', 'desc')
  );
  const snapshot = await getDocs(q);
  const data = snapshot.docs.map((doc) => {
    // return orderDocumentSchema.parse(doc.data());
    return doc.data();
  });

  if (typeof data === 'undefined') {
    return { results: null };
  }
  return { results: data };
};

export const getSellerOrdersByState = async (uid: string, state: number) => {
  const q = query(
    ordersRef,
    where('seller_arr', 'array-contains', uid),
    where('total_state', '==', state),
    orderBy('created', 'desc')
  );
  const snap = await getDocs(q);
  const results = snap.docs.map((doc) => doc.data());
  return { results };
};

export async function getPendingOrders(seller_id: string) {
  const q = query(
    ordersRef,
    where('seller_arr', 'array-contains', seller_id),
    where('total_state', 'in', [0, 1])
  );
  const snap = await getDocs(q);
  const results = snap.docs.map((doc) => doc.data());
  return results;
}

export function getOrderId(): string {
  const newDocRef = doc(ordersRef);
  const id = newDocRef.id;
  return id;
}

export async function getOrderNum(sellerIds: string[]): Promise<string> {
  try {
    if (!Array.isArray(sellerIds) || sellerIds.length === 0) {
      throw new Error('Seller IDs must be a non-empty array.');
    }

    const getOrderNumber = httpsCallable(functions, 'orders-getOrderNumber');
    const response = await getOrderNumber({ sellerIds });

    if (response?.data) {
      return response.data as string;
    } else {
      throw new Error('Invalid response from Cloud Function.');
    }
  } catch (error) {
    console.error('Error getting order number:', error);

    // Return fallback order number on error
    const currentDate = new Date();
    const yearLastDigit = String(currentDate.getFullYear()).slice(-1);
    const monthLetter = String.fromCharCode(65 + currentDate.getMonth());
    const dayOfMonth = String(currentDate.getDate()).padStart(2, '0');
    return `GFEGON0-${yearLastDigit}${monthLetter}${dayOfMonth}-000000`;
  }
}

export async function getOrderByPaymentId(payment_id: string) {
  const q = query(ordersRef, where('payment_id', '==', payment_id), limit(1));
  const snap = await getDocs(q);
  return snap.empty ? null : snap.docs[0].data();
}

export async function getOrderAdmin(orderNumOrStripeId: string) {
  const c = orderNumOrStripeId.startsWith('ch_')
    ? where('payment_id', '==', orderNumOrStripeId)
    : where('order_num', '==', Number(orderNumOrStripeId));
  const q = query(ordersRef, c, limit(1));
  const snap = await getDocs(q);
  return snap.empty ? null : snap.docs[0].data();
}

// product_ids are the items the buyer is marking as received
export async function markAsReceived(orderId: string, product_ids: string[]) {
  const res = await httpsCallable<
    { id: string; product_ids: string[] },
    { data: 'success' }
  >(
    functions,
    'markAsReceived'
  )({ id: orderId, product_ids });
  return res.data;
}

export async function addNewNote(orderId: string, note: string): Promise<void> {
  // add a new note to the order
  const newNote: OrderNoteType = {
    text: note,
    created: Date.now(),
  };
  await updateDoc(doc(ordersRef, orderId), {
    notes: arrayUnion(newNote),
  });
}

export async function deleteNote(
  orderId: string,
  note: OrderNoteType
): Promise<void> {
  // delete a note from the order
  await updateDoc(doc(ordersRef, orderId), {
    notes: arrayRemove(note),
  });
}

interface StartTrackingArgs {
  carrier: CarrierCode;
  trackingNo: string;
  orderId: string;
  sellerId: string;
  productId: string;
}

interface StartTrackingError {
  status: 'error';
  message: string;
}

interface StartTrackingSuccess {
  status: 'success';
}

export type StartTrackingResponse = StartTrackingError | StartTrackingSuccess;
export async function startTracking(
  data: StartTrackingArgs
): Promise<StartTrackingResponse> {
  data.trackingNo = data.trackingNo.trim();
  // call the startTracking firebase function
  try {
    await httpsCallable<{}, StartTrackingArgs>(
      functions,
      'startTracking'
    )(data);
    return {
      status: 'success',
    };
  } catch (e) {
    logError(e);
    if (
      !!e &&
      typeof e === 'object' &&
      'message' in e &&
      typeof e.message === 'string'
    ) {
      return {
        status: 'error',
        message: e.message,
      };
    }
    return {
      status: 'error',
      message: 'Unknown error',
    };
  }
}

type ShopOverviewResponse = {
  data: {
    week: {
      chart: [number, number][];
      total: number;
    };
    month: {
      chart: [number, number][];
      total: number;
    };
    lmonth: {
      chart: [number, number][];
      total: number;
    };
    year: {
      chart: [number, number][];
      total: number;
    };
    lyear: {
      chart: [number, number][];
      total: number;
    };
    all: {
      chart: [number, number][];
      total: number;
    };
  };
  recent_orders: OrderDocument[];
  pending_orders: number;
  completed_orders: number;
  available_balance: number;
  actual_pending: number;
  currency: string;
};

export async function getShopOverview() {
  const res = await httpsCallable<{}, ShopOverviewResponse>(
    functions,
    'getShopOverview'
  )({});
  return res.data;
}

export const reasons = [
  z.literal('duplicate'),
  z.literal('fraudulent'),
  z.literal('requested_by_customer'),
  z.literal('requested_by_seller'),
  z.literal('product_return'),
  z.literal('cancelled_order'),
] as const;
export const reasonSchema = z.union(reasons);
export type Reason = z.infer<typeof reasonSchema>;

export const labels = [
  'Duplicate',
  'Fraudulent',
  'Requested by customer',
  'Requested by seller',
  'Product return',
  'Cancelled order',
];

export const ALL_REASONS = reasons.map((reason) => reason.value);

type refundOrderArgs = {
  reason: Reason;
  product_ids: string[];
  partial_amount?: number;
  order: OrderDocument;
};

export async function refundOrder(args: refundOrderArgs) {
  const res = await httpsCallable<refundOrderArgs, {}>(
    functions,
    'refundOrder'
  )(args);
  return res.data;
}

export const calculateEarnings = (
  item: OrderItemDocument,
  buyerState: string = '',
  sellerTaxStates: string[] = [],
  fee = 0.05
) => {
  const subtotal = item.product_cost * item.qty;
  const total = subtotal + item.buyer_shipping_cost + (item.product_tax || 0);
  const processing = total * 0.03;
  const shipping =
    !item.rate_id && item.buyer_shipping_cost ? item.buyer_shipping_cost : 0;
  const label_cost = item.rate_id ? item.buyer_shipping_cost : 0;
  const commission = fee * (shipping + subtotal);
  const sellerRemits = sellerTaxStates.includes(buyerState);
  const taxes = sellerRemits ? 0 : item.product_tax ?? 0;
  const ins_amount = item.insurance_amount ?? 0;
  const net = total - processing - commission - label_cost - taxes - ins_amount;
  return [net, commission, taxes];
};
