import { ZodError } from "zod";
import LoadingSpinner from '@c/LoadingSpinner';
import CategorySkeleton from '@c/skeletons/CategorySkeleton';
import { FirebaseError } from '@firebase/app';
import {
  CreatePaymentArgs,
  MinimalOrderDocument,
  UpdatePaymentArgs,
} from '@util/firestore/payments/payments.types';
import { getOrderId, getOrderNum } from './firestore/orders';
import { getProductById } from './firestore/products';
import { Coupon } from './firestore/stripe';
import { AddressDocument } from './types/firestore/address';
import { CartItem, cartItemSchema } from './types/firestore/carts';
import {
  OrderDocument,
  OrderItemDocument,
  orderItemDocumentSchema,
} from './types/firestore/order';
import { ProductDocument } from './types/firestore/products';
import { UserDocument } from './types/firestore/users';
import { parsePhoneNumber } from 'libphonenumber-js';
export const IMAGE_PATH = 'https://gearfocus.b-cdn.net/' as const;
export const IMAGE_EXT = '.jpeg' as const;

const moment = require('moment');

export function getLoadingIndicator(v?: LoadingIndicatorVariants) {
  if (v === 'categorySkeleton') return CategorySkeleton;
  return LoadingSpinner;
}

type LoadingIndicatorVariants = 'spinner' | 'categorySkeleton';

export const getCartItemFromProductDocument = (
  product: ProductDocument
): CartItem | null => {
  try {
    return cartItemSchema.parse({
      product_id: product.id,
      product_sku: product.sku,
      product_title: product.title,
      product_image: product.thumbnail,
      product_link: product.slug,
      product_cost: product.price,
      product_brand: product.brand,
      product_categories: product.categories,
      product_master: product.master || "",
      external_source: product.external_source || "gearfocus",
      seller_id: product.seller_id,
      qty: 1,
      account_id: product.account_id,
      is_flat_rate: product.is_flat_rate,
    });
  } catch (error) {
    if (error instanceof ZodError) {
      console.error("Zod validation error:", error?.errors);
    } else {
      console.error("Unexpected error:", error); // Log unexpected errors
    }
    return null; // Return null or handle appropriately in case of an error
  }
};

export const cartItemToProductDocument = async (
  item: CartItem
): Promise<ProductDocument | null> => {
  return getProductById(item.product_id);
};

export const cartItemToOrderItem = (
  item: CartItem & { rate_id?: string } //TODO: maybe add rate_id to cartItemSchema?
): OrderItemDocument => {
  return orderItemDocumentSchema.parse({
    id: item.product_id,
    qty: item.qty,
    state: 8, //Set Initial State For Item To "Processing"
    product_cost: item.product_cost,
    buyer_shipping_cost: item.shipping_cost ?? 0,
    rate_id: item.rate_id,
  });
};

export function formatAddress(address: AddressDocument): string {
  return `${address.address_line1}, ${address.city_locality} ${address.state_province} ${address.postal_code}, ${address.country_code}`;
}

const metaData = (order: MinimalOrderDocument) => {
  const items = Object.keys(order.sellers!).reduce((acc, key) => {
    order.sellers![key].forEach((item) => {
      (item as any).seller_id = key;
      acc[item.id] = JSON.stringify(item);
      return acc;
    });
    return acc;
  }, {} as { [seller_id: string]: string });
  return {
    ...items,
    order_id: order.id ?? '',
    buyer_id: order.buyer_id,
    shipping_cost: order.shipping_total ? order.shipping_total.toFixed(2) : '0',
    from_web: 'true',
    ...(order.donate && { donation: 'R2R' }),
    ...(order.coupon?.id && { promo: order.coupon?.id }),
  };
};

export async function getUpdatePaymentIntentArgs({
  pi_id,
  address,
  items,
  userDoc,
  coupons,
  tax,
  taxPerItem,
  affirm,
}: {
  coupons: Coupon[];
  pi_id: string;
  address: AddressDocument;
  items: Array<CartItem & { rate_id?: string }>;
  userDoc: UserDocument;
  tax: number;
  taxPerItem?: Map<string, number>;
  affirm: boolean;
}): Promise<UpdatePaymentArgs> {
  const id = getOrderId();
  if (!id) throw new Error('Could not get order id');

  const c = coupons?.[0]; // just using 1 coupon for MVP
  const coupon: OrderDocument['coupon'] = c
    ? {
        id: c.id,
        code: c.code,
        name: c.name,
        amount_off: c.amount_off,
        percent_off: c.percent_off,
        off: undefined,
      }
    : undefined;

  const sellers = items.reduce<{ [key: string]: OrderItemDocument[] }>(
    (acc, item) => {
      const orderItem = cartItemToOrderItem(item);
      orderItem.product_tax = taxPerItem?.get(item.product_id) ?? 0;
      if (acc[item.seller_id]) {
        acc[item.seller_id].push(orderItem);
      } else {
        acc[item.seller_id] = [orderItem];
      }
      return acc;
    },
    {}
  );

  const item_count = items.reduce((acc, item) => acc + item.qty, 0);

  const itemsTotal = items.reduce((acc, item) => {
    return acc + item.product_cost * item.qty;
  }, 0);

  const discountTotal = (() => {
    if (!coupon) return 0;
    if (!!coupon.amount_off) {
      return coupon.amount_off;
    }
    if (!!coupon.percent_off) {
      const percentOff = coupon.percent_off;
      return itemsTotal * (percentOff / 100);
    }
    return 0;
  })();

  const subtotal = itemsTotal - discountTotal;

  const shippingTotal = items.reduce((acc, item) => {
    return acc + (item.shipping_cost ?? 0);
  }, 0);

  const total = subtotal + shippingTotal + tax;
  // get temp order_num so zod parse doesn't fail (backend still hasn't finished)
  const order_num = await getOrderNum(Object.keys(sellers));
  const order = {
    address,
    buyer_id: userDoc.uid,
    coupon,
    customer_id: userDoc.customer_id,
    donate: false,
    id,
    order_num,
    item_count,
    payment: { type: 0, saved: true },
    product_ids: items.map((item) => item.product_id),
    sellers,
    shipping_total: shippingTotal,
    subtotal,
    total,
    tax,
  } satisfies MinimalOrderDocument;

  return {
    pi_id,
    address,
    amount: total,
    order,
    metadata: metaData(order),
    affirm,
  };
}

export function getCreatePaymentIntentArgs({
  items,
  userDoc,
}: {
  items: CartItem[];
  userDoc: UserDocument;
}): CreatePaymentArgs {
  const sellers = Object.fromEntries(
    items.map((item) => [item.seller_id, [cartItemToOrderItem(item)]])
  );
  let calculatedTotal = 0;
  items.forEach((item) => {
    calculatedTotal += item.product_cost * item.qty + (item.shipping_cost ?? 0);
  });
  const item_count = items.reduce((acc, item) => acc + item.qty, 0);

  // not including all of the Order fields, only the ones needed for creating a payment intent
  const order = {
    sellers,
    total: calculatedTotal,
    subtotal: calculatedTotal,
    shipping_total: 0,
    item_count,
    buyer_id: userDoc.uid,
    address: userDoc.addresses?.[0],
    customer_id: userDoc.customer_id,
    payment: { type: 0, saved: true },
    product_ids: items.map((item) => item.product_id),
  } as unknown as OrderDocument;
  return {
    amount: calculatedTotal,
    order,
    metadata: metaData(order),
  };
}

export function formatCurrency(number: number, currency = 'USD') {
  const upper = currency.toUpperCase();
  const locale = 'en-US';

  if (upper === 'CAD')
    return (
      new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'USD',
      }).format(number) + ' CAD'
    );

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: upper,
  }).format(number);
}

export function formatPhoneNumber(phoneNumber: string) {
  if (!phoneNumber) return phoneNumber;
  try {
    const cleaned = parsePhoneNumber(phoneNumber);
    if (!cleaned) return phoneNumber;
    return `+${cleaned.countryCallingCode} ${cleaned.formatNational()}`;
  } catch (e) {
    return phoneNumber;
  }
}

export function obfuscatePhoneNumber(phoneNumber: string) {
  if (!phoneNumber) return phoneNumber;
  try {
    const cleaned = formatPhoneNumber(phoneNumber);
    const digitsOnly = cleaned.replace(/\D/g, ''); // Remove non-digit characters
    
    if (digitsOnly.length >= 4) {
      const beginningDigits = digitsOnly[0] + digitsOnly[1] + digitsOnly[2];
      const lastDigit = digitsOnly[digitsOnly.length - 1];
      const obfuscatedMiddle = '#'.repeat(digitsOnly.length - 3); // -2 to keep first and last digits

      // Reconstruct the obfuscated phone number
      return beginningDigits + obfuscatedMiddle + lastDigit;
    }
    return phoneNumber; // If the phone number is too short, return it as is
    
  } catch (e) {
    return phoneNumber;
  }
}

export function obfuscateEmailAddress(email: string) {
  if (!email) return email;
  try {
    const atIndex = email.indexOf('@');

    if (atIndex > 0) {
      const username = email.substring(0, atIndex);
      const obfuscatedUsername = obfuscateString(username);

      const domain = email.substring(atIndex);      
      const dotIndex = domain.lastIndexOf('.');

      if (dotIndex >= 0) {
        const subdomain = domain.substring(0, dotIndex);
        const tld = domain.substring(dotIndex);
        const emailEnding = subdomain.substring(0, 2) + '#'.repeat(subdomain.length - 2);
        return obfuscatedUsername + emailEnding + tld;
      }
      
      return obfuscatedUsername + domain;
    }
    
    return email;
    
  } catch (e) {
    return email;
  }
}

export function obfuscateString(input: string): string {
  if (input.length <= 2) {
    return input; // No need to obfuscate short strings
  }
  
  const firstTwoChars = input.substring(0, 2);
  const obfuscatedMiddle = '#'.repeat(input.length - 2);
  
  return firstTwoChars + obfuscatedMiddle;
}

export function daysUntilExpiration(timestamp: number) {
  if (timestamp.toString().length === 10) timestamp *= 1000;

  const currentTimestamp = Date.now();
  const timeDifference = currentTimestamp - timestamp;

  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const daysUntil30DaysOld = Math.max(30 - daysDifference, 0);

  return daysUntil30DaysOld;
}
export function formatTimestamp(timestamp: number) {
  if (timestamp.toString().length === 10) timestamp *= 1000;
  return new Date(timestamp).toLocaleString(undefined, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}
export function formatTime(timestamp: number) {
  const date = new Date(timestamp);
  return date.toLocaleTimeString(undefined, {
    hour: 'numeric',
    minute: '2-digit',
  });
}

export function formatAuthError(error: FirebaseError) {
  const words = error.code.replace('auth/', '').replaceAll('-', ' ').split(' ');
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join(' ');
}

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export function getHostUrl() {
  if (process.env.NODE_ENV === 'production') {
    const host = process.env.NEXT_PUBLIC_VERCEL_URL ?? 'gearfocus.com';
    return `https://${host}`;
  }
  if (process.env.NODE_ENV === 'development') return 'http://localhost:3000';
  throw new Error('host not configured for current environment');
}

export const CURRENCY_MAP = {
  usd: 'US Dollar',
  cad: 'Canadian Dollar',
  eur: 'Euro',
  aud: 'Australian Dollar',
  hkd: 'Hong Kong Dollar',
  mxn: 'Mexican Peso',
  nzd: 'New Zealand Dollar',
  nok: 'Norwegian Krone',
  sgd: 'Singapore Dollar',
  chf: 'Swiss Franc',
  thb: 'Thai Baht',
  gbp: 'British Pound',
} as const;

export function getSellerRegistrationCurrencyOptions(): {
  label: string;
  value: string;
  id: string;
}[] {
  const allCurrency = getSellerRegistrationCountries().map((c) => c.currency);
  return [...new Set(allCurrency)].sort().map((c) => ({
    label: CURRENCY_MAP[c],
    value: c,
    id: c,
  }));
}

export function getSellerRegistrationCountries() {
  const countries = [
    {
      code: 'US',
      name: 'United States',
      currency: 'usd',
    },
    {
      code: 'CA',
      name: 'Canada',
      currency: 'cad',
    },
    { code: 'AT', name: 'Austria', currency: 'eur' },
    {
      code: 'AU',
      name: 'Australia',
      currency: 'aud',
    },
    { code: 'BE', name: 'Belgium', currency: 'eur' },
    { code: 'BG', name: 'Bulgaria', currency: 'eur' },
    { code: 'HR', name: 'Croatia', currency: 'eur' },
    { code: 'CY', name: 'Cyprus', currency: 'eur' },
    {
      code: 'CZ',
      name: 'Czech Republic',
      currency: 'eur',
    },
    { code: 'DK', name: 'Denmark', currency: 'eur' },
    { code: 'EE', name: 'Estonia', currency: 'eur' },
    { code: 'FI', name: 'Finland', currency: 'eur' },
    { code: 'FR', name: 'France', currency: 'eur' },
    { code: 'DE', name: 'Germany', currency: 'eur' },
    { code: 'GR', name: 'Greece', currency: 'eur' },
    {
      code: 'HK',
      name: 'Hong Kong',
      currency: 'hkd',
    },
    { code: 'HU', name: 'Hungary', currency: 'eur' },
    { code: 'IE', name: 'Ireland', currency: 'eur' },
    { code: 'IT', name: 'Italy', currency: 'eur' },
    { code: 'LV', name: 'Latvia', currency: 'eur' },
    { code: 'LT', name: 'Lithuania', currency: 'eur' },
    { code: 'LU', name: 'Luxembourg', currency: 'eur' },
    { code: 'MT', name: 'Malta', currency: 'eur' },
    {
      code: 'MX',
      name: 'Mexico',
      currency: 'mxn',
    },
    { code: 'NL', name: 'Netherlands', currency: 'eur' },
    {
      code: 'NZ',
      name: 'New Zealand',
      currency: 'nzd',
    },
    {
      code: 'NO',
      name: 'Norway',
      currency: 'nok',
    },
    { code: 'PL', name: 'Poland', currency: 'eur' },
    { code: 'PT', name: 'Portugal', currency: 'eur' },
    { code: 'RO', name: 'Romania', currency: 'eur' },
    {
      code: 'SG',
      name: 'Singapore',
      currency: 'sgd',
    },
    { code: 'SK', name: 'Slovakia', currency: 'eur' },
    { code: 'SI', name: 'Slovenia', currency: 'eur' },
    { code: 'ES', name: 'Spain', currency: 'eur' },
    { code: 'SE', name: 'Sweden', currency: 'eur' },
    {
      code: 'CH',
      name: 'Switzerland',
      currency: 'chf',
    },
    {
      code: 'TH',
      name: 'Thailand',
      currency: 'thb',
    },
    {
      code: 'GB',
      name: 'United Kingdom',
      currency: 'gbp',
    },
  ] as const;
  return countries;
}

export const offerStateToText = (state: number) => {
  switch (state) {
    case 1:
      return 'Accepted';
    case 2:
      return 'Declined';
    case 3:
      return 'Cancelled';
    default:
      return 'Pending';
  }
};

export const timeAgo = (timestamp?: number) => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  if (!Intl || typeof Intl.RelativeTimeFormat !== 'function')
    return date.toLocaleString(); // unsupported browser
  const formatter = new Intl.RelativeTimeFormat('en', { style: 'narrow' });
  const ranges: Partial<Record<Intl.RelativeTimeFormatUnit, number>> = {
    years: 3600 * 24 * 365,
    months: 3600 * 24 * 30,
    weeks: 3600 * 24 * 7,
    days: 3600 * 24,
    hours: 3600,
    minutes: 60,
    seconds: 1,
    second: 1,
  };
  const secondsElapsed = (date.getTime() - Date.now()) / 1000;
  for (let key in ranges) {
    const range = key as Intl.RelativeTimeFormatUnit;
    if (ranges[range] && ranges[range]! < Math.abs(secondsElapsed)) {
      const delta = secondsElapsed / ranges[range]!;
      return formatter.format(Math.round(delta), range);
    } else if (key === 'second') return 'just now';
  }
};

// Hours minutes and seconds until timestamp is reached in the format #H #M #S
export const timeRemaining = (timestamp?: number) => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  const secondsRemaining = (date.getTime() - Date.now()) / 1000;

  const hours = Math.floor(secondsRemaining / 3600);
  const minutes = Math.floor((secondsRemaining % 3600) / 60);
  const seconds = Math.floor(secondsRemaining % 60);

  const timeRemaining = `${hours}h ${minutes}m ${seconds}s`;
  return timeRemaining;
};

export function isMobile() {
  return typeof window !== 'undefined' && window.innerWidth <= 768;
}

export function isTablet() {
  return typeof window !== 'undefined' && window.innerWidth <= 1024;
}

export function isDesktop() {
  return typeof window !== 'undefined' && window.innerWidth > 1024;
}

export function isLargeDesktop() {
  return typeof window !== 'undefined' && window.innerWidth > 1280;
}

export const debounce = (func: any, delay: number) => {
  let timerId: any;
  return function (this: any, ...args: any[]) {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const capitalize = (word?: string) => {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const diffInDaysFromNow = (date: Date) => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  return diff / (1000 * 3600 * 24);
};

export const DaysSinceLastUpdated = (timestamp: number) => {
  const listingDate = moment(timestamp).startOf('day');
  const currentDate = moment();

  return currentDate.diff(listingDate, 'days');
}  

export const timestampToDatetimelocal = (timestamp: number) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return `${year}-${
    month < 10 ? `0${month}` : month
  }-${day}T${hours}:${minutes}:${seconds}`;
};

export const datetimeLocalToTimestamp = (datetimeLocal: string) => {
  const date = new Date(datetimeLocal);
  return date.getTime();
};

export const getDurationInDays = (start: number, end: number): number => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const diff = endDate.getTime() - startDate.getTime();
  return Math.floor(diff / (1000 * 3600 * 24));
};

export const timestampFromDuration = (duration: number, start: string) => {
  const now = new Date(start);
  const timestamp = now.getTime() + duration * 1000 * 3600 * 24;
  return timestamp;
};

export const getEndDate = (duration: number, start: string) => {
  const date = new Date(timestampFromDuration(duration, start));
  // return date and time
  return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
};

export const getDatePartsFromTimestamp = (timestamp?: number) => {
  if (!timestamp) return;
  const date = new Date(timestamp);
  const hour = date.getHours();
  const minute = date.getMinutes();

  const hourIn12 = hour % 12;

  return {
    date: date.toISOString().split('T')[0],
    hour: hourIn12 < 10 ? `0${hourIn12}` : `${hourIn12}`,
    minute: minute < 10 ? `0${minute}` : `${minute}`,
    AMPM: hour < 12 ? 'AM' : 'PM',
  };
};

export const inBetween = (startTime: string, duration: string) => {
  const now = new Date();
  const start = new Date(startTime);
  const end = new Date(timestampFromDuration(Number(duration), startTime));
  return now.getTime() > start.getTime() && now.getTime() < end.getTime();
};
