interface Props {
  image: React.ReactNode;
  children?: React.ReactNode;
  reverse?: boolean;
}

const CallToAction = ({ image, children, reverse }: Props) => {
  return (
    <div
      className={`relative m-auto flex w-full max-w-[120rem] flex-col-reverse overflow-clip sm:flex-row sm:rounded-[10px]  ${
        reverse ? 'sm:flex-row-reverse' : ''
      } `}
    >
      <div
        className={`flex h-full w-full flex-col justify-between p-[0rem] py-[1.6rem] text-brand-white sm:absolute lg:py-[4.8rem] lg:pl-[3.2rem] ${
          reverse ? 'left-0' : 'right-0'
        } top-0 bg-brand-black sm:max-w-[60rem] lg:min-h-[34.5rem]`}
      >
        {children}
      </div>

      <div>{image}</div>
    </div>
  );
};

export default CallToAction;
