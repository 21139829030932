import FormLabel from '@c/forms/controls/FormLabel';
import FormSelect from '@c/forms/controls/FormSelect';
import FormTextarea from '@c/forms/controls/FormTextarea';
import DynamicToast from '@c/toasts/DynamicToast';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@ui/Button';
import { createChat, getNextCaseNumber } from '@util/firestore/messages';
import { updateOrderByKey } from '@util/firestore/orders';
import {
  ChatDocumentSchema,
  ChatMessagesDocumentSchema,
} from '@util/types/firestore/chat';
import { slugify } from '@util/urlHelpers';
import { useAuth } from 'context/AuthContext';
import { useToastContext } from 'context/ToastContext';
import React from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import BaseModal from './BaseModal';

export const SUPPORT_ID = 'KAA1SDJQn1SkRXB4Mzywf28aPFx1';

const formSchema = z.object({
  issue: z.string(),
  comment: z.string().optional(),
});

type Form = z.infer<typeof formSchema>;

interface HelpRequestModalProps {
  isOpen: boolean;
  dismiss?: () => void;
  order_id?: string;
  order_num?: number | string;
  variant?: 'buyer' | 'seller';
}
export default function HelpRequestModal({
  isOpen,
  dismiss,
  order_id,
  order_num,
  variant = 'buyer',
}: HelpRequestModalProps) {
  const { userDoc } = useAuth();
  const { showToast } = useToastContext();
  const [loadingSubmit, setLoadingSubmit] = React.useState(false);
  const { register, control, handleSubmit } = useForm<Form>({
    mode: 'onSubmit',
    resolver: zodResolver(formSchema),
  });
  const requestSubmitted = () => {
    showToast(
      <DynamicToast
        text={'Help sent to our support, please wait for their response.'}
      />,
      {
        style: {
          borderRadius: '16px',
          backgroundColor: '#FDF4E9',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: '#EF9021',
          width: 'min-content',
        },
      }
    );
  };
  const queryClient = useQueryClient();

  const onSubmit = async (data: Form) => {
    if (!userDoc) return;
    setLoadingSubmit(true);
    let content = `Issue: ${data.issue}.`;
    if (data.comment) content += ` Comment: ${data.comment}`;
    const now = Date.now();

    const message = ChatMessagesDocumentSchema.parse({
      uid: userDoc?.uid,
      content,
      created_at: now,
    });

    const case_num = await getNextCaseNumber();
    const chat = ChatDocumentSchema.parse({
      uids: [userDoc?.uid, SUPPORT_ID],
      order_num,
      order_id,
      buyer_id: variant === 'buyer' ? userDoc?.uid : SUPPORT_ID,
      seller_id: variant === 'buyer' ? SUPPORT_ID : userDoc?.uid,
      last_time: now,
      created_at: now,
      messages: [message],
      case_num,
      unread: {
        [SUPPORT_ID]: true,
      },
    });

    if (order_id && variant === 'buyer') {
      await updateOrderByKey(order_id, 'total_state', 3);
      queryClient.invalidateQueries(['orders', order_id]);
    }
    await createChat(chat);
    requestSubmitted();
    dismiss?.();
    setLoadingSubmit(false);
  };

  const getOptions = () => {
    if (variant === 'buyer') {
      return [
        "I haven't received it",
        "It's different than expected",
        "It's damaged or defective",
        "It's missing items or parts",
        'Something Else',
      ].map((issue) => ({
        label: issue,
        value: issue,
        id: slugify(issue),
      }));
    } else {
      return [
        'Item no longer in stock',
        'Help with shipping',
        'When will I get paid?',
        'Something Else',
      ].map((issue) => ({
        label: issue,
        value: issue,
        id: slugify(issue),
      }));
    }
  };
  return (
    <BaseModal
      isOpen={isOpen}
      dismiss={dismiss}
      title={
        <h3 className="text-[2.4rem] font-semibold">Help with your order</h3>
      }
      overflowType="visible"
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-[44rem] max-w-[90vw] flex-col gap-[2.4rem] p-4"
      >
        <span className="text-brand-gray">
          Tell us the problem you are experiencing
        </span>
        <FormLabel value="What happened?" required>
          <FormSelect
            control={control}
            name="issue"
            allowEmpty={false}
            placeholder="Select an issue"
            options={getOptions()}
          />
        </FormLabel>
        <FormLabel value="Additional Comment (Optional)">
          <FormTextarea
            {...register('comment')}
            placeholder="Tell us more about your issue..."
          />
        </FormLabel>
        <div className="flex flex-col justify-end gap-[1.2rem] font-semibold text-brand-secondary sm:flex-row">
          <Button type="text" text="Cancel" width="small" onClick={dismiss} />
          <Button
            buttonType="submit"
            type="secondary"
            text="Request Help"
            loading={loadingSubmit}
          />
        </div>
      </form>
    </BaseModal>
  );
}
