import SafeImage from '../SafeImage';
import Link from 'next/link';
import { StaticImageData } from 'next/image';
type Props = {
  href: string;
  src: string | StaticImageData;
  alt: string;
  label: string;
  onClick?: () => void;
  variant: 'square' | 'tall' | 'centered';
  scaleOnHover?: boolean;
  heightWidthOverride?: { height: number; width: number };
};

const ImageLink = ({
  href,
  src,
  alt,
  label,
  onClick,
  variant,
  scaleOnHover = true,
  heightWidthOverride,
}: Props) => {
  const scaleOnHoverStyle = scaleOnHover ? 'hover:scale-110' : '';
  const handleClick = () => {
    onClick?.();
  };
  if (variant === 'square') {
    return (
      <Link
        href={href}
        onClick={handleClick}
        className="relative min-h-[18rem] min-w-[15rem] overflow-hidden rounded-[10px] bg-brand-lightest-gray hover:bg-gradient-orange hover:text-brand-white sm:min-w-[18rem]"
      >
        <span className="absolute top-2 w-full px-[2rem] text-center font-semibold">
          {label}
        </span>
        <div className="absolute bottom-0">
          <SafeImage src={src} alt={alt} width={180} height={180} priority />
        </div>
      </Link>
    );
  } else if (variant === 'tall') {
    return (
      <Link
        href={href}
        className="relative overflow-clip rounded-[10px] bg-brand-light-gray w-[45%] h-[20rem]lg:h-[38rem] lg:w-[23.5%]"
      >
        <div className="aspect-w-3 aspect-h-2 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-4 lg:aspect-h-3">
          <SafeImage src={src} alt={alt} className='w-[100%] h-[100%] content-cover' />
        </div>
        {/* overlay */}
        <div className="absolute inset-0 flex bg-brand-black opacity-30 transition-opacity hover:opacity-60"></div>
        {/* end overlay */}
        {/* Label */}
        <div className="pointer-events-none absolute bottom-6 left-6 pr-[4.8rem] text-h3 font-semibold text-brand-white sm:text-h2">
          {label}
        </div>
        {/* end label */}
      </Link>

    );
  } else {
    return (
      <Link
        href={href}
        onClick={handleClick}
        className={`relative flex h-[16rem] w-[16rem] items-center justify-center rounded-lg bg-brand-white lg:scale-100 ${scaleOnHoverStyle}`}
      >
        <span className="absolute bottom-[1.2rem] left-[1.2rem] right-[1.2rem] z-10 font-semibold">
          {label}
        </span>
        {/* overlay */}
        <div className="absolute left-0 top-0 flex h-full w-full bg-gradient-black"></div>
        {/* end overlay */}
        <SafeImage
          src={src}
          alt={alt}
          width={100}
          height={100}
          {...heightWidthOverride}
        />
      </Link>
    );
  }
};

export default ImageLink;
